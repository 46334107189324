// console.log('Weather Widget')


// const ZIP = "90292"
// const API_KEY = "92682a03fc954711be0532131befb277"
// const OPEN_WEATHER_URL = `https://api.openweathermap.org/data/2.5/weather?zip=${ZIP},us&APPID=${API_KEY}`



// fetch(OPEN_WEATHER_URL)
//   .then(function(response) {
//     return response.json();
//   })
//   .then(function(myJson) {
//     console.log(JSON.stringify(myJson));
//   });

// Need error handling

