const foxhole = (myString) => {
  console.log(myString);
}

foxhole('boom');


// const URL = 'http://api.openweathermap.org/data/2.5/weather?zip=90291,us&APPID=92682a03fc954711be0532131befb277'


// async function getUserAsync(name) 
// {
//   let response = await fetch(URL;
//   let data = await response.json()
//   return data;
// }

// getUserAsync('yourUsernameHere')
//   .then(data => console.log(data)); 








// var para = document.createElement("h4");
// var t = document.createTextNode("Javascript is working");
// para.appendChild(t);
// document.getElementById("root").appendChild(para);

// // bikini;

// console.log('boom')
