console.log('I am fetch-api')
const SBC_URL = 'https://www.siliconbeachcoding.com/api/members'



fetch(SBC_URL)
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    console.log('Members: ' + JSON.stringify(myJson));
  }).catch((err) =>  {
    console.log('Fetch problem: ' + err.message)
  })
